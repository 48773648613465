class Formatter {
  /**
   * Возвращает date object
   * @param day
   * @param month - в формате js (0 - январь, 1 - февраль)
   * @param year
   * @returns {Date}
   */
  paramsDateToJSObject(day, month, year) {
    return new Date(year, month, day);
  }

  utcPhpDateTimeToTime(dt){
      const dateTime = new Date(dt);
      const clientOffsetMinutes = new Date().getTimezoneOffset();
      const clientLocalTime = new Date(dateTime.getTime() - (clientOffsetMinutes * 60 * 1000));
      const phpDatetime = this.jsObjectToPhpDatetime(clientLocalTime);
      return phpDatetime.split(' ')[1];
  }

  paramsDatetimeToJsObject(day, month, year, hours, minutes, seconds){
    return new Date(year, month, day, hours, minutes, seconds);
  }

  datetimeToRus(datetime){
    let datetimeSplit = datetime.split(' ');
    let dateRus = this.phpDateToRus(datetimeSplit[0]);
    let time = datetimeSplit[1];
    return dateRus +' ' +time;
  }


  /**
   * Переводит объект даты JS в формат PHP
   * @param obj
   * @returns {string}
   */
  jsObjectToPhp(obj) {
    return this.paramsDateToPhpFormat(
      obj.getDate(),
      obj.getMonth(),
      obj.getFullYear()
    );
  }

  /**
   * Переводит объект js в datetime формата php
   * @param {Date()} obj 
   * @returns {string}
   */
  jsObjectToPhpDatetime(obj){
    let dt = this.paramsDateToPhpFormat(
      obj.getDate(),
      obj.getMonth(),
      obj.getFullYear()
    );

    return dt +' ' +( obj.getHours() <= 9 ? '0'+obj.getHours() : obj.getHours() ) +
      ':' +( obj.getMinutes() <= 9 ? '0'+obj.getMinutes() : obj.getMinutes() ) +
      ':' + (obj.getSeconds() <= 9 ? '0'+obj.getSeconds() : obj.getSeconds() );
  }

  phpDatetimeToJsObject(datetime){
    let newDate = new Date(datetime);
    return this.paramsDatetimeToJsObject(
      newDate.getDate(),
      newDate.getMonth(),
      newDate.getFullYear(),
      newDate.getHours(),
      newDate.getMinutes(),
      newDate.getSeconds()
    );
  }

  /**
   * Переводит дату из YYYY-MM-DD в объект JS.
   * Почему такой способ? Потому что new Date('yyyy-dd-mm') вхерачивает часовой пояс
   * @param date
   * @returns {Date}
   */
  phpDateToJSObject(date) {
    let newDate = new Date(date);
    return this.paramsDateToJSObject(
      newDate.getDate(),
      newDate.getMonth(),
      newDate.getFullYear()
    );
  }

  /**
   * Форматирует параметры даты в строку php
   * @param day
   * @param month - в формате js (0 - январь, 1 - февраль)
   * @param year
   * @returns {string}
   */
  paramsDateToPhpFormat(day, month, year) {
    let getObj = this.paramsDateToJSObject(day, month, year);
    return this.dateToPhpFormat(getObj);
  }

  /**
   * Форматирует дату из объекта js в строку php
   * @param date object dateObj
   * @returns {string} 'YYYY-MM-DD'
   */
  dateToPhpFormat(dateObj) {
    let day = dateObj.getDate();
    let month = dateObj.getMonth() + 1;
    let year = dateObj.getFullYear();
    return (
      year +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      (day < 10 ? "0" + day : day)
    );
  }

  /**
   * Форматирует дату вида '2020-01-01' в '01 января 2020'
   * @param string date - YYYY-MM-DD
   * @returns {string} '01 января 2020'
   */
  phpDateToString(date) {
    let month = [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря"
    ];
    let dateObj = new Date(date);
    let day =
      dateObj.getDate() < 10 ? "0" + dateObj.getDate() : dateObj.getDate();
    return day + " " + month[dateObj.getMonth()] + " " + dateObj.getFullYear();
  }

  /**
   * Форматирует датувремя вида '2020-01-01 00:00:00' в '01 янв 2020 - 00:00'
   * @param string date - YYYY-MM-DD
   * @returns {string} '01 янв 2020'
   */
  phpDateTimeToShortString(date) {
    if(date == '') return '';
    let month = ["янв", "фев", "мар", "апр", "май", "июн", "июл", "авг", "сент", "окт", "ноя", "дек"];
    let dateObj = new Date(date);
    let day = dateObj.getDate() < 10 ? "0" + dateObj.getDate() : dateObj.getDate();
    let hours = dateObj.getHours() < 10 ? "0" + dateObj.getHours() : dateObj.getHours();
    let minutes = dateObj.getMinutes() < 10 ? "0" + dateObj.getMinutes() : dateObj.getMinutes();
    let dateText = day + " " + month[dateObj.getMonth()] + " " + dateObj.getFullYear();
    return dateText +' - ' + hours +':' +minutes;
  }

  /**
   * Форматирует дату из формата dd.mm.yyyy в объект Date JS
   * @param string date - dd.mm.yyyy
   * @returns {Date}
   */
  rusDateToJSObject(date) {
    let dateSplit = date.split(".");
    return this.paramsDateToJSObject(
      +dateSplit[0],
      +dateSplit[1] - 1,
      +dateSplit[2]
    );
  }

  /**
   * Переводит параметры даты в формат dd.mm.yyyy
   * @param day
   * @param month
   * @param year
   * @returns {string}
   */
  dateParamsToRus(day, month, year) {
    let resDay = +day < 10 ? "0" + day : day;
    let resMonth = +month < 10 ? "0" + month : month;
    let resYear = year;
    return resDay + "." + resMonth + "." + resYear;
  }

  /**
   * Переводит дату из формата YYYY-MM-DD в dd.mm.yyyy
   * @param date
   * @returns {string}
   */
  phpDateToRus(date) {
    if(date == '') return '';
    let dateParams = this.phpDateToJSObject(date);
    return this.dateParamsToRus(
      dateParams.getDate(),
      dateParams.getMonth() + 1,
      dateParams.getFullYear()
    );
  }

  /**
   * Переводит минуты в часы
   * @param integer minute
   * @returns {string}
   */
  minuteToTime(minute) {
    let hour = Math.floor(minute / 60);
    let minutes = minute % 60 < 10 ? "0" + (minute % 60) : minute % 60;

    return hour + ":" + minutes;
  }

  /**
   * Переводит время в минуты
   * @param time
   * @returns {number}
   */
  timeToMinute(time){
    let timeSplit = time.split(':');
    return (+timeSplit[0] *60) + +timeSplit[1];
  }

  /**
   * Разбивает минуты на 2 параметра hour, minute
   * @param minutes
   * @returns {{}}
   */
  minuteSplitHourMinute(minutes) {
    let result = {};
    result.hour = Math.floor(minutes / 60);
    result.minute = minutes - Math.floor(minutes / 60) * 60;
    return result;
  }

  /**
   * Минуты в человекопонятное время вида: 1 ч. 20 мин.
   * @param minutes
   * @returns {string}
   */
  minuteSplitHumanHourMinute(minutes) {
    let time = this.minuteSplitHourMinute(minutes);
    if (time.hour === 0) {
      return time.minute + " мин.";
    } else {
      return time.hour + " ч. " + time.minute + " мин.";
    }
  }

  /**
   * Переводит часы и минуты в минуты
   * @param hour
   * @param minute
   * @returns {number}
   */
  hourMinuteToMinute(hour, minute) {
    return +hour * 60 + +minute;
  }

  /**
   * Преобразование строки true/false к типу Boolean
   * @param val
   * @returns {boolean}
   */
  boolStringToType(val){
    if(val === 'true' || val === 'TRUE'){
      return true;
    }else if(val === 'false' || val === 'FALSE'){
      return false;
    }else{
      return false;
    }
  }

  /**
   * Преобразовывает номер телефона к маске
   * @param number - 79999999999
   * @returns {string} - +7(999)999-9999
   */
  phoneNumberToMask(number){
    let maskObject = {
      7: '(###)###-####',
      375: '(##)###-####',
      380: '(##)###-####',
      998: '(##)###-####',
      1: '(###)###-####',
      49: '(###)###-#####',
      33: '(###)###-###',
    };
    let result = '';
    let phone = number.replace(/^\+/, '');
    let existsMask = false;
    for(let key in maskObject){
      let regexp = new RegExp(`^${key}`);
      if(phone.match(regexp)){
        existsMask = true;
        let mask = maskObject[key];
        phone = phone.replace(regexp, '');

        for(let z = 0; z < mask.length; z++){
          if(mask[z] == '#'){
            result += phone[0];
            phone = phone.slice(1);
          }else{
            result += mask[z];
          }
        }
        result = '+' +key +result;
        break;
      }
    }
    if(!existsMask){
      return '+' +number;
    }
    return result;
  }

}

export default Formatter;
